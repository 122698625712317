import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Link, navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import BEMHelper from 'react-bem-helper'
import useWindowSize from '../../hooks/useWindowSize'
import { useSwipeable } from 'react-swipeable'

import './ImageGallery.scss'

const bem = new BEMHelper('image-gallery')

const config = {
  delta: 10, // min distance(px) before a swipe starts. *See Notes*
  preventScrollOnSwipe: true, // prevents scroll during swipe (*See Details*)
  trackTouch: true, // track touch input
  trackMouse: true, // track mouse input
  rotationAngle: 0, // set a rotation angle
  swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
  touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
}

const ImageGallery = ({ images, slug, currentImage }) => {
  const width = useWindowSize()[0]
  const isMobile = width < 1024

  const imageIndex = useMemo(
    () => images.findIndex((img) => img.asset.id === currentImage?.asset.id),
    [images, currentImage]
  )

  const [opacity, setOpacity] = useState(0)

  const getImage = useCallback(
    (index) => {
      const image = images[index]
      return navigate(`/artists/${slug}?photo=${image.asset.id}`)
    },
    [images, slug]
  )

  const getPrevImage = useCallback(() => {
    const newIndex = imageIndex === 0 ? images.length - 1 : imageIndex - 1
    getImage(newIndex)
  }, [getImage, imageIndex, images.length])

  const getNextImage = useCallback(() => {
    const newIndex = imageIndex === images.length - 1 ? 0 : imageIndex + 1
    getImage(newIndex)
  }, [getImage, imageIndex, images.length])

  const handleMouseDown = (event) => {
    const { right, width } = event.target.getBoundingClientRect()
    const clickRight = right - event.clientX < width / 2
    return clickRight ? getNextImage() : getPrevImage()
  }

  const handlers = useSwipeable({
    onSwipeStart: isMobile ? () => setOpacity(0) : null,
    onSwipedRight: isMobile ? () => getPrevImage() : null,
    onSwipedLeft: isMobile ? () => getNextImage() : null,
    onTouchStartOrOnMouseDown: ({ event }) =>
      !isMobile ? handleMouseDown(event) : null,
    ...config,
  })

  useEffect(() => {
    setOpacity(0)

    setTimeout(() => {
      setOpacity(1)
    }, 100)
  }, [imageIndex])

  useEffect(() => {
    const ALLOWED_KEYS = ['ArrowLeft', 'ArrowRight']

    const handleKeyDown = ({ key }) => {
      if (!ALLOWED_KEYS.includes(key)) {
        return
      }
      return key === 'ArrowLeft' ? getPrevImage() : getNextImage()
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [imageIndex, getNextImage, getPrevImage])

  return (
    <div {...bem()}>
      <div {...bem('count')}>
        <div>
          <span {...bem('count-number')}>{imageIndex + 1}</span>/
          <span {...bem('count-number', 'total')}>{images.length}</span>
        </div>
        {isMobile && <Link to={`/artists/${slug}`}>(back)</Link>}
        {!isMobile && <Link to={'/artists'}>(back)</Link>}
      </div>
      {currentImage && (
        <div
          {...bem('image-wrapper', opacity === 1 ? 'opacity' : '')}
          {...handlers}
        >
          <GatsbyImage
            image={currentImage.asset.gatsbyImageData}
            alt={''}
            objectFit="contain"
          />
        </div>
      )}
    </div>
  )
}

export default ImageGallery
