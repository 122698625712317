import React from 'react'
import BEMHelper from 'react-bem-helper'
import ReactMasonry from 'react-masonry-css'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import useWindowSize from '../../hooks/useWindowSize'

import './Masonry.scss'

const bem = new BEMHelper('masonry')

const Masonry = ({ artist }) => {
  const width = useWindowSize()[0]
  const isMobile = width < 1024

  const { images, slug } = artist

  const handleClick = () => {
    if (isMobile) {
      return
    }
    sessionStorage.setItem('artistId', artist.id)
  }

  return (
    <ReactMasonry
      {...bem()}
      breakpointCols={{ default: 2 }}
      columnClassName="masonry__column"
    >
      {images.map(({ asset }) => (
        <Link
          key={asset.id}
          to={`/artists/${slug.current}?photo=${asset.id}`}
          onClick={handleClick}
        >
          <GatsbyImage image={asset.gatsbyImageData} alt={''} />
        </Link>
      ))}
    </ReactMasonry>
  )
}

export default Masonry
