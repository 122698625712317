import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import BEMHelper from 'react-bem-helper'
import ImageGallery from '../../components/ImageGallery'
import useWindowSize from '../../hooks/useWindowSize'
import Masonry from '../../components/Masonry/Masonry'

import './Artist.scss'

const bem = new BEMHelper('artist')
const bemMobile = new BEMHelper('artist-mobile')

const Artist = ({ data: { artist }, pageContext, location }) => {
  const { prev, next } = pageContext
  const { name, _rawBio: bio, images, slug } = artist
  const width = useWindowSize()[0]
  const isMobile = width < 1024

  const [showBio, setShowBio] = useState(false)
  const [selectedImage, setSelectedImage] = useState()
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (!location.search) {
      return setSelectedImage(images[0])
    }

    const locationImageId = location.search?.slice(7)
    const newSelectedImage = images.find(
      (img) => img.asset.id === locationImageId
    )
    setSelectedImage(newSelectedImage)
  }, [location.search, images])

  if (!hasMounted) {
    return null
  }

  if (isMobile) {
    return (
      <div {...bemMobile()}>
        {!!location.search && (
          <div {...bemMobile('content')}>
            <ImageGallery
              images={images}
              slug={slug.current}
              currentImage={selectedImage}
            />
          </div>
        )}
        {!location.search && (
          <>
            <div {...bemMobile('details')}>
              <div {...bemMobile('title-wrapper')}>
                <h1 {...bemMobile('title', showBio ? 'hidden' : '')}>{name}</h1>
                {bio && (
                  <button
                    {...bemMobile('btn-bio')}
                    onClick={() => setShowBio(!showBio)}
                  >
                    {`(${showBio ? 'close' : 'bio'})`}
                  </button>
                )}
              </div>
              {bio && (
                <div {...bemMobile('bio', showBio ? '' : 'hidden')}>
                  <BlockContent blocks={bio} />
                </div>
              )}
              <div {...bemMobile('controls', showBio ? 'hidden' : '')}>
                <Link
                  to={`/artists/${prev}`}
                  {...bemMobile('control', prev ? '' : 'hidden')}
                >
                  (previous)
                </Link>
                <Link
                  to={`/artists/${next}`}
                  disabled={!next}
                  {...bemMobile('control', next ? '' : 'hidden')}
                >
                  (next)
                </Link>
              </div>
            </div>
            <div {...bemMobile('content')}>
              <Masonry artist={artist} />
            </div>
          </>
        )}
      </div>
    )
  }

  return (
    <div {...bem()}>
      <div {...bem('details')}>
        <div {...bem('title-wrapper')}>
          <h1 {...bem('title', showBio ? 'hidden' : '')}>{name}</h1>
          {bio && (
            <button {...bem('btn-bio')} onClick={() => setShowBio(!showBio)}>
              {`(${showBio ? 'close' : 'bio'})`}
            </button>
          )}
        </div>
        {bio && (
          <div {...bem('bio', showBio ? '' : 'hidden')}>
            <BlockContent blocks={bio} />
          </div>
        )}
      </div>
      <div {...bem('content')}>
        <ImageGallery
          images={images}
          slug={slug.current}
          currentImage={selectedImage}
        />
      </div>
    </div>
  )
}

export default Artist

export const query = graphql`
  query ($slug: String!) {
    artist: sanityArtist(slug: { current: { eq: $slug } }) {
      _rawBio
      id
      slug {
        current
      }
      images {
        asset {
          id
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      name
    }
  }
`
